import React from 'react';
//import PropTypes from 'prop-types';

import RealHomePage from '../components/real-pages/home';

function HomePage(props) {
  return (<RealHomePage />);
}

HomePage.propTypes = {

};

export default HomePage;
