import React from 'react';
//import PropTypes from 'prop-types';

import Layout from '../../layout';
import Header from '../../home-sections/header';
import AboutUs from '../../home-sections/about-us';
import Services from '../../home-sections/services';
import Portfolio from '../../home-sections/portfolio';
import BigSeparator from '../../home-sections/big-separator';
import ContactUs from '../../home-sections/contact-us';
import HorizontalLine from '../../ui/horizontal-line';

import { configureBEMClasses } from '../../../constants/functions';

import styles from './styles.module.scss';
const classes = configureBEMClasses(styles);

function RealHomePage(props) {
  return (
    <Layout>
      <Header />
      <HorizontalLine lineClasses={classes.line} />
      <AboutUs />
      <HorizontalLine />
      <Services />
      <Portfolio />
      <BigSeparator />
      <ContactUs />
    </Layout>
  );
}

RealHomePage.propTypes = {

};

export default RealHomePage;
