import React, { useCallback, useEffect } from 'react';
//import PropTypes from 'prop-types';

import { configureBEMClasses } from '../../../constants/functions';

import styles from './styles.module.scss';
const classes = configureBEMClasses(styles);

function AboutUs(props) {

    const onScrollBackgroundAnimation = useCallback(() => {
        if (document) {
            const backgrounAnimatedElement = document.querySelector('.' + classes['background--grey']);

            if (backgrounAnimatedElement) {
                backgrounAnimatedElement.style.setProperty('--scroll', window.pageYOffset / (document.body.offsetHeight - window.innerHeight));
            }
        }
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', onScrollBackgroundAnimation, false);

        return () => {
            document.removeEventListener('scroll', onScrollBackgroundAnimation);
        }
    }, [onScrollBackgroundAnimation]);

    return (
        <section className={classes['about-us']}>
            <section className={classes['about-us__backgrounds']}>
                <div className={[classes.background, classes['background--white']].join(' ')}></div>
                <div className={[classes.background, classes['background--black']].join(' ')}></div>
                <div className={[classes.background, classes['background--grey']].join(' ')}></div>
            </section>
            <section className={classes['about-us__content-container']}>
                <div className={classes['about-us__content']}>
                    <h2 className="section-heading">About us</h2>
                    <p>Welcome to Amigos Barbershop, the home of traditional barbering where we provide the best service at the highest standard. Amigos was founded in 2020 by Kilian Maddison who has worked at the peak of the barbering industry, building an impressive portfolio and perfecting his technique.</p>
                    <p>With experience gained from travelling Europe, working for globally known brands and cutting hair at events such as Formula 1 at the Silverstone circuit, he achieved a career goal by winning the UK Barber of the year award.</p>
                    <p>At Amigos we are all about customer satisfaction. We use an online system to make your booking not only easy, with no wait or queue, but with that personal touch too.  From Classic Italian barbering, cut throat shaves and even a free beer whilst you wait, we aim to accommodate anything you require.</p>
                </div>
            </section>
        </section>
    );
}

AboutUs.propTypes = {

};

export default AboutUs;