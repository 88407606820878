import React from 'react';
//import PropTypes from 'prop-types';

import LinkButton from '../../ui/link-button';
import Image from '../../ui/image';

import { configureBEMClasses } from '../../../constants/functions';

import styles from './styles.module.scss';
const classes = configureBEMClasses(styles);

function Header(props) {
    return (
        <header className={classes.header}>
            <LinkButton url="/" linkClasses={classes['header__back-button']}>&#8619; Go back</LinkButton>

            <h1 className={classes['header__heading']}>
                <Image
                    imageType="svg"
                    imageName="logo"
                    imageExtention="svg"
                    alt="This is site logo."
                    imageClasses={classes['header__logo']}
                />
                <Image
                    pathToNormalImageFolder="/logos"
                    imageType="normal"
                    imageName="header-text"
                    imageExtention="png"
                    alt="The home of traditional barbering."
                />
            </h1>

            <div className={classes['header__images-container']}>
                <div className={classes['header__owner-image-container']}>
                    <Image
                        pathToNormalImageFolder="/people-faces"
                        imageType="normal"
                        imageName="avatar"
                        imageExtention="jpg"
                        alt="This is a handsome men with nice haircut."
                    />
                </div>

                <div className={[classes.background, classes['background--white'], classes['background--white--small']].join(' ')}></div>
                <div className={[classes.background, classes['background--white'], classes['background--white--medium']].join(' ')}></div>
                <div className={[classes.background, classes['background--white'], classes['background--white--big']].join(' ')}></div>
                <div className={[classes.background, classes['background--grey']].join(' ')}></div>
            </div>
        </header>
    );
}

Header.propTypes = {

};

export default Header;