import React, { useState, useEffect, useCallback, Fragment } from 'react';
//import PropTypes from 'prop-types';
import AOS from 'aos';

import SEO from '../ui/seo';
import Footer from './footer';

import { configureBEMClasses } from '../../constants/functions';

import 'aos/dist/aos.css';
import '../../assets/styles/application-styles.scss';
import styles from './styles.module.scss';
const classes = configureBEMClasses(styles);

function Layout(props) {
    const [applicationHtml, setApplicationHtml] = useState(null);

    const onScreenSize = useCallback(() => {
        let newHtml = (
            <div className={classes.application}>
                <main>
                    {props.children}
                </main>
                <Footer></Footer>
            </div>
        );

        if (window.outerWidth <= 300) {
            newHtml = (
                <div className={classes.application}>
                    <main className={classes['application__screen-error']}>
                        <p>Sorry, our site does not support resolutions with width below 300 pixels.</p>
                    </main>
                </div>
            );
        }

        setApplicationHtml(newHtml);
    }, [props.children]);

    useEffect(() => {
        AOS.init({
            // Global settings:
            disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
            startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
            initClassName: 'aos-init', // class applied after initialization
            animatedClassName: 'aos-animate', // class applied on animation
            useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
            disableMutationObserver: false, // disables automatic mutations' detections (advanced)
            debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
            throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
        
        
            // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
            offset: 120, // offset (in px) from the original trigger point
            delay: 0, // values from 0 to 3000, with step 50ms
            duration: 400, // values from 0 to 3000, with step 50ms
            easing: 'ease', // default easing for AOS animations
            once: false, // whether animation should happen only once - while scrolling down
            mirror: false, // whether elements should animate out while scrolling past them
            anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
        });
        
        onScreenSize();

        window.addEventListener('resize', onScreenSize);

        return () => {
            window.removeEventListener('resize', onScreenSize);
        }
    }, [setApplicationHtml, onScreenSize]);

    return (
        <Fragment>
            <SEO />
            {applicationHtml}
        </Fragment>
    );
}

Layout.propTypes = {

};

export default Layout;