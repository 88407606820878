import React, { useState, useEffect, useCallback } from 'react';
//import PropTypes from 'prop-types';
import Slider from "react-slick";

import Image from '../../ui/image';

import { configureBEMClasses } from '../../../constants/functions';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './slider.scss';
import styles from './styles.module.scss';
const classes = configureBEMClasses(styles);

function Portfolio(props) {
    const [headerImage, setHeaderImage] = useState(<Image
        pathToNormalImageFolder="/logos"
        imageType="normal"
        imageName="logo-portfolio"
        imageExtention="png"
        alt="This is a portfolio text."
    />);

    const sliderSettings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 10000,
    };

    const changeImage = (event) => {
        function getImageContainerGridClass(className) {
            const regex = /--[0-9]+--/gm;

            if (regex.test(className)) {
                return true;
            }
        }

        const clickedImage = event.currentTarget;

        const bigImageClass = classes['portfolio__gallery-image-container--1'];
        const currentImageClass = Array.from(clickedImage.classList)
            .find((className) => getImageContainerGridClass(className));

        if (bigImageClass === currentImageClass) {
            return;
        }

        const sectionOfCurrentGrid = clickedImage.parentNode;
        const bigImage = sectionOfCurrentGrid.querySelector('.' + bigImageClass);

        bigImage.classList.remove(bigImageClass);
        bigImage.classList.add(currentImageClass);

        clickedImage.classList.remove(currentImageClass);
        clickedImage.classList.add(bigImageClass);
    }

    const changeHeaderImage = useCallback(() => {
        let newHeaderImage = (
            <Image
                pathToNormalImageFolder="/logos"
                imageType="normal"
                imageName="logo-portfolio"
                imageExtention="png"
                alt="This is a portfolio text."
            />
        );

        if (window.outerWidth < 1350) {
            newHeaderImage = (
                <Image
                    pathToNormalImageFolder="/logos"
                    imageType="normal"
                    imageName="portfolio-text"
                    imageExtention="png"
                    alt="This is a portfolio text."
                />
            );
        }

        setHeaderImage(newHeaderImage);
    }, [setHeaderImage]);

    useEffect(() => {
        changeHeaderImage();

        window.addEventListener('resize', changeHeaderImage);

        return () => {
            window.removeEventListener('resize', changeHeaderImage);
        }
    }, [changeHeaderImage]);

    return (
        <section className={classes.portfolio}>
            <header
                data-aos="fade"
                data-aos-offset="200"
                data-aos-delay="0"
                data-aos-duration="1000"
                data-aos-anchor-placement="top-bottom"
                className={classes['portfolio__header']}
            >
                <h2 className={classes['portfolio__header-heading']}>
                    {headerImage}
                </h2>
            </header>

            <section className={classes['portfolio__slider-container']}>
                <div className={classes['portfolio__slider']}>
                    <Slider {...sliderSettings}>
                        <div className="slick-slide">
                            <section
                                data-aos="fade-left"
                                data-aos-offset="200"
                                data-aos-delay="100"
                                data-aos-duration="1000"
                                data-aos-anchor-placement="top-bottom"
                                className={classes['portfolio__gallery']}
                            >
                                <div
                                    className={[classes['portfolio__gallery-image-container'], classes['portfolio__gallery-image-container--1']].join(' ')}
                                    onClick={changeImage}
                                    onKeyDown={changeImage}
                                    role="button"
                                    tabIndex={0}
                                >
                                    <Image
                                        pathToNormalImageFolder="/portfolio"
                                        imageType="normal"
                                        imageName="16"
                                        imageExtention="jpg"
                                        alt="This is a person-one with nice haircut."
                                    />
                                </div>

                                <div
                                    className={[classes['portfolio__gallery-image-container'], classes['portfolio__gallery-image-container--2']].join(' ')}
                                    onClick={changeImage}
                                    onKeyDown={changeImage}
                                    role="button"
                                    tabIndex={0}
                                >
                                    <Image
                                        pathToNormalImageFolder="/portfolio"
                                        imageType="normal"
                                        imageName="17"
                                        imageExtention="jpg"
                                        alt="This is a person-two with nice haircut."
                                    />
                                </div>

                                <div
                                    className={[classes['portfolio__gallery-image-container'], classes['portfolio__gallery-image-container--3']].join(' ')}
                                    onClick={changeImage}
                                    onKeyDown={changeImage}
                                    role="button"
                                    tabIndex={0}
                                >
                                    <Image
                                        pathToNormalImageFolder="/portfolio"
                                        imageType="normal"
                                        imageName="18"
                                        imageExtention="jpg"
                                        alt="This is a person-three with nice haircut."
                                    />
                                </div>
                            </section>
                        </div>
                        <div className="slick-slide">
                            <section
                                data-aos="fade-left"
                                data-aos-offset="200"
                                data-aos-delay="100"
                                data-aos-duration="1000"
                                data-aos-anchor-placement="top-bottom"
                                className={classes['portfolio__gallery']}
                            >
                                <div
                                    className={[classes['portfolio__gallery-image-container'], classes['portfolio__gallery-image-container--1']].join(' ')}
                                    onClick={changeImage}
                                    onKeyDown={changeImage}
                                    role="button"
                                    tabIndex={0}
                                >
                                    <Image
                                        pathToNormalImageFolder="/portfolio"
                                        imageType="normal"
                                        imageName="13"
                                        imageExtention="jpg"
                                        alt="This is a person-one with nice haircut."
                                    />
                                </div>

                                <div
                                    className={[classes['portfolio__gallery-image-container'], classes['portfolio__gallery-image-container--2']].join(' ')}
                                    onClick={changeImage}
                                    onKeyDown={changeImage}
                                    role="button"
                                    tabIndex={0}
                                >
                                    <Image
                                        pathToNormalImageFolder="/portfolio"
                                        imageType="normal"
                                        imageName="14"
                                        imageExtention="jpg"
                                        alt="This is a person-two with nice haircut."
                                    />
                                </div>

                                <div
                                    className={[classes['portfolio__gallery-image-container'], classes['portfolio__gallery-image-container--3']].join(' ')}
                                    onClick={changeImage}
                                    onKeyDown={changeImage}
                                    role="button"
                                    tabIndex={0}
                                >
                                    <Image
                                        pathToNormalImageFolder="/portfolio"
                                        imageType="normal"
                                        imageName="15"
                                        imageExtention="jpg"
                                        alt="This is a person-three with nice haircut."
                                    />
                                </div>
                            </section>
                        </div>
                        <div className="slick-slide">
                            <section
                                data-aos="fade-left"
                                data-aos-offset="200"
                                data-aos-delay="100"
                                data-aos-duration="1000"
                                data-aos-anchor-placement="top-bottom"
                                className={classes['portfolio__gallery']}
                            >
                                <div
                                    className={[classes['portfolio__gallery-image-container'], classes['portfolio__gallery-image-container--1']].join(' ')}
                                    onClick={changeImage}
                                    onKeyDown={changeImage}
                                    role="button"
                                    tabIndex={0}
                                >
                                    <Image
                                        pathToNormalImageFolder="/portfolio"
                                        imageType="normal"
                                        imageName="1"
                                        imageExtention="jpg"
                                        alt="This is a person-one with nice haircut."
                                    />
                                </div>

                                <div
                                    className={[classes['portfolio__gallery-image-container'], classes['portfolio__gallery-image-container--2']].join(' ')}
                                    onClick={changeImage}
                                    onKeyDown={changeImage}
                                    role="button"
                                    tabIndex={0}
                                >
                                    <Image
                                        pathToNormalImageFolder="/portfolio"
                                        imageType="normal"
                                        imageName="2"
                                        imageExtention="jpg"
                                        alt="This is a person-two with nice haircut."
                                    />
                                </div>

                                <div
                                    className={[classes['portfolio__gallery-image-container'], classes['portfolio__gallery-image-container--3']].join(' ')}
                                    onClick={changeImage}
                                    onKeyDown={changeImage}
                                    role="button"
                                    tabIndex={0}
                                >
                                    <Image
                                        pathToNormalImageFolder="/portfolio"
                                        imageType="normal"
                                        imageName="3"
                                        imageExtention="jpg"
                                        alt="This is a person-three with nice haircut."
                                    />
                                </div>
                            </section>
                        </div>
                        <div className="slick-slide">
                            <section
                                data-aos="fade-left"
                                data-aos-offset="200"
                                data-aos-delay="100"
                                data-aos-duration="1000"
                                data-aos-anchor-placement="top-bottom"
                                className={classes['portfolio__gallery']}
                            >
                                <div
                                    className={[classes['portfolio__gallery-image-container'], classes['portfolio__gallery-image-container--1']].join(' ')}
                                    onClick={changeImage}
                                    onKeyDown={changeImage}
                                    role="button"
                                    tabIndex={0}
                                >
                                    <Image
                                        pathToNormalImageFolder="/portfolio"
                                        imageType="normal"
                                        imageName="4"
                                        imageExtention="jpg"
                                        alt="This is a person-one with nice haircut."
                                    />
                                </div>

                                <div
                                    className={[classes['portfolio__gallery-image-container'], classes['portfolio__gallery-image-container--2']].join(' ')}
                                    onClick={changeImage}
                                    onKeyDown={changeImage}
                                    role="button"
                                    tabIndex={0}
                                >
                                    <Image
                                        pathToNormalImageFolder="/portfolio"
                                        imageType="normal"
                                        imageName="5"
                                        imageExtention="jpg"
                                        alt="This is a person-two with nice haircut."
                                    />
                                </div>

                                <div
                                    className={[classes['portfolio__gallery-image-container'], classes['portfolio__gallery-image-container--3']].join(' ')}
                                    onClick={changeImage}
                                    onKeyDown={changeImage}
                                    role="button"
                                    tabIndex={0}
                                >
                                    <Image
                                        pathToNormalImageFolder="/portfolio"
                                        imageType="normal"
                                        imageName="6"
                                        imageExtention="jpg"
                                        alt="This is a person-three with nice haircut."
                                    />
                                </div>
                            </section>
                        </div>
                        <div className="slick-slide">
                            <section
                                data-aos="fade-left"
                                data-aos-offset="200"
                                data-aos-delay="100"
                                data-aos-duration="1000"
                                data-aos-anchor-placement="top-bottom"
                                className={classes['portfolio__gallery']}
                            >
                                <div
                                    className={[classes['portfolio__gallery-image-container'], classes['portfolio__gallery-image-container--1']].join(' ')}
                                    onClick={changeImage}
                                    onKeyDown={changeImage}
                                    role="button"
                                    tabIndex={0}
                                >
                                    <Image
                                        pathToNormalImageFolder="/portfolio"
                                        imageType="normal"
                                        imageName="7"
                                        imageExtention="jpg"
                                        alt="This is a person-one with nice haircut."
                                    />
                                </div>

                                <div
                                    className={[classes['portfolio__gallery-image-container'], classes['portfolio__gallery-image-container--2']].join(' ')}
                                    onClick={changeImage}
                                    onKeyDown={changeImage}
                                    role="button"
                                    tabIndex={0}
                                >
                                    <Image
                                        pathToNormalImageFolder="/portfolio"
                                        imageType="normal"
                                        imageName="8"
                                        imageExtention="jpg"
                                        alt="This is a person-two with nice haircut."
                                    />
                                </div>

                                <div
                                    className={[classes['portfolio__gallery-image-container'], classes['portfolio__gallery-image-container--3']].join(' ')}
                                    onClick={changeImage}
                                    onKeyDown={changeImage}
                                    role="button"
                                    tabIndex={0}
                                >
                                    <Image
                                        pathToNormalImageFolder="/portfolio"
                                        imageType="normal"
                                        imageName="9"
                                        imageExtention="jpg"
                                        alt="This is a person-three with nice haircut."
                                    />
                                </div>
                            </section>
                        </div>
                        <div className="slick-slide">
                            <section
                                data-aos="fade-left"
                                data-aos-offset="200"
                                data-aos-delay="100"
                                data-aos-duration="1000"
                                data-aos-anchor-placement="top-bottom"
                                className={classes['portfolio__gallery']}
                            >
                                <div
                                    className={[classes['portfolio__gallery-image-container'], classes['portfolio__gallery-image-container--1']].join(' ')}
                                    onClick={changeImage}
                                    onKeyDown={changeImage}
                                    role="button"
                                    tabIndex={0}
                                >
                                    <Image
                                        pathToNormalImageFolder="/portfolio"
                                        imageType="normal"
                                        imageName="10"
                                        imageExtention="jpg"
                                        alt="This is a person-one with nice haircut."
                                    />
                                </div>

                                <div
                                    className={[classes['portfolio__gallery-image-container'], classes['portfolio__gallery-image-container--2']].join(' ')}
                                    onClick={changeImage}
                                    onKeyDown={changeImage}
                                    role="button"
                                    tabIndex={0}
                                >
                                    <Image
                                        pathToNormalImageFolder="/portfolio"
                                        imageType="normal"
                                        imageName="11"
                                        imageExtention="jpg"
                                        alt="This is a person-two with nice haircut."
                                    />
                                </div>

                                <div
                                    className={[classes['portfolio__gallery-image-container'], classes['portfolio__gallery-image-container--3']].join(' ')}
                                    onClick={changeImage}
                                    onKeyDown={changeImage}
                                    role="button"
                                    tabIndex={0}
                                >
                                    <Image
                                        pathToNormalImageFolder="/portfolio"
                                        imageType="normal"
                                        imageName="12"
                                        imageExtention="jpg"
                                        alt="This is a person-three with nice haircut."
                                    />
                                </div>
                            </section>
                        </div>
                    </Slider>
                </div>
            </section>
        </section>
    );
}

Portfolio.propTypes = {

};

export default Portfolio;