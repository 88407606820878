import React from 'react';
//import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';

import Card from './card';
import Image from '../../ui/image';

import { configureBEMClasses } from '../../../constants/functions';

import styles from './styles.module.scss';
const classes = configureBEMClasses(styles);

const getAllServicesQuery = graphql`
query GetAllServices {
    allFile(filter: {sourceInstanceName: {eq: "services"}}, sort: {fields: childMarkdownRemark___frontmatter___orderIndex}) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              name
              price
            }
          }
        }
      }
    }
  }
`;

function Services(props) {
  const data = useStaticQuery(getAllServicesQuery);

  const services = data.allFile.edges;

  const servicesCards = services.map((edge, index) => {
    const service = edge.node.childMarkdownRemark;

    return (<Card
      key={service.frontmatter.name + index}
      title={service.frontmatter.name}
      price={service.frontmatter.price}
      html={service.html}
    />)
  });

  return (
    <section className={classes.services}>
      <div className={classes['services__main-background']}></div>
      <section className={classes['services__content-container']}>
        <div className={classes['services__content']}>

          <Image
            imageType="svg"
            imageName="logo-black"
            imageExtention="svg"
            alt="This is application logo."
            imageClasses={classes['services__logo']}
          />

          <h2 className="section-heading">Services</h2>
          {servicesCards}
        </div>
      </section>

      <section className={classes['services__backgrounds']}>
        <div
          data-aos="services-background"
          data-aos-offset="200"
          data-aos-delay="500"
          data-aos-duration="1000"
          data-aos-once="false"
          data-aos-anchor-placement="top-bottom"
          className={[classes.background, classes['background--black']].join(' ')}
        >
        </div>

        <div
          data-aos="services-background"
          data-aos-offset="200"
          data-aos-delay="0"
          data-aos-duration="1000"
          data-aos-once="false"
          data-aos-anchor-placement="top-bottom"
          className={[classes.background, classes['background--white']].join(' ')}
        >
        </div>

        <div
          data-aos="services-background"
          data-aos-offset="200"
          data-aos-delay="200"
          data-aos-duration="1000"
          data-aos-once="false"
          data-aos-anchor-placement="top-bottom"
          className={[classes.background, classes['background--grey']].join(' ')}
        >
        </div>
      </section>
    </section>
  );
}

Services.propTypes = {

};

export default Services;