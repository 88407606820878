import React from 'react';
//import PropTypes from 'prop-types';

import Image from '../../ui/image';
import LinkButton from '../../ui/link-button';

import { configureBEMClasses } from '../../../constants/functions';

import styles from './styles.module.scss';
const classes = configureBEMClasses(styles);

function Footer(props) {
    const currentYear = new Date().getUTCFullYear();

    return (
        <footer className={classes.footer}>
            <Image
                pathToNormalImageFolder="/logos"
                imageType="normal"
                imageName="logo-moustache"
                imageExtention="png"
                alt="This is a nice moustache."
                imageClasses={classes['footer__image']}
            />

            <div className={classes['footer__information']}>
                <p>Amigos Barbers Ltd. All Rights Reserved {currentYear}</p>
            </div>

            <LinkButton isOuterLink={true} url="https://imaginarica.com/" targetUrl="_blank" linkClasses={classes['imaginarica-logo']}>
                <div>
                    <Image
                        pathToNormalImageFolder="/logos"
                        imageType="normal"
                        imageName="imaginarica"
                        imageExtention="png"
                        alt="This is imaginarica logo."
                    />
                    <span className={classes['imaginarica-logo__text']}>Site design &amp; development</span>
                </div>
            </LinkButton>

        </footer>
    );
}

Footer.propTypes = {

};

export default Footer;