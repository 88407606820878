import React from 'react';
//import PropTypes from 'prop-types';

import Image from '../../ui/image';
import LinkButton from '../../ui/link-button';

import { configureBEMClasses } from '../../../constants/functions';

import styles from './styles.module.scss';
const classes = configureBEMClasses(styles);



function ContactUs(props) {
    const data = {
        telephone: '07432041320',
        email: 'kilianmaddison@hotmail.co.uk',
        bookLink: 'https://booksy.com/en-gb/47331_amigos-traditional-barbers_barber-shop_651577_springhead',
    }

    return (
        <section className={classes['contact-us']}>
            <div
                data-aos="zoom-in-up"
                data-aos-offset="200"
                data-aos-delay="0"
                data-aos-duration="700"
                data-aos-anchor-placement="top-bottom"
            >
                <Image
                    imageType="svg"
                    imageName="logo"
                    imageExtention="svg"
                    alt="This is application logo."
                    imageClasses={classes.logo}
                />
                <header className={classes['contact-us__header']}>
                    <h2 className="section-heading">Contact us</h2>
                    <p>27 Oldham Road, Springhead, OL4 4PH, Oldham</p>
                </header>
                <section className={classes['contact-us__links']}>
                    <LinkButton
                        url={"tel:" + data.telephone}
                        linkClasses={classes['contact-us__link']}
                        isOuterLink={true}
                    >
                        Tel : {data.telephone}
                    </LinkButton>

                    <LinkButton
                        url={"mailto: " + data.email}
                        linkClasses={classes['contact-us__link']}
                        isOuterLink={true}
                    >
                        E : {data.email}
                    </LinkButton>

                    <LinkButton isOuterLink={true} targetUrl="_blank" url={data.bookLink} linkClasses={classes['contact-us__link']}>Book an appointment</LinkButton>

                    <LinkButton 
                        isOuterLink={true} 
                        url="https://www.instagram.com/amigos_barbers/" 
                        targetUrl="_blank" 
                        linkClasses={[classes['contact-us__link'], classes['instagram']].join(' ')}>
                        <Image
                            pathToNormalImageFolder="/logos"
                            imageType="normal"
                            imageName="instagram"
                            imageExtention="png"
                            alt="This is a nice moustache."
                            imageClasses={classes['instagram_image']}
                        />
                    </LinkButton>
                </section>
            </div>
        </section>
    );
}

ContactUs.propTypes = {

};

export default ContactUs;