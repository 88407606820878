import React from 'react';
import PropTypes from 'prop-types';

import { configureBEMClasses } from '../../../constants/functions';

import styles from './styles.module.scss';
const classes = configureBEMClasses(styles);

function HorizontalLine(props) {
    const lineClasses = [classes.line, props.lineClasses].join(' ');

    return (
        <hr className={lineClasses} />
    );
}

HorizontalLine.propTypes = {
    lineClasses: PropTypes.string,
};

export default HorizontalLine;