import React from 'react';
//import PropTypes from 'prop-types';

import { configureBEMClasses } from '../../../constants/functions';

import styles from './styles.module.scss';
const classes = configureBEMClasses(styles);

function BigSeparator(props) {
    return (
        <section className={classes.separators}>
            <div
                data-aos="slide-right"
                data-aos-offset="200"
                data-aos-delay="0"
                data-aos-duration="1000"
                data-aos-anchor-placement="top-bottom"
                className={[classes.background, classes['background--grey'], classes['background--grey--up']].join(' ')}
            >
            </div>

            <div
                data-aos="slide-left"
                data-aos-offset="200"
                data-aos-delay="0"
                data-aos-duration="700"
                data-aos-anchor-placement="top-bottom"
                className={[classes.background, classes['background--black']].join(' ')}
            >
            </div>

            <div
                data-aos="slide-right"
                data-aos-offset="200"
                data-aos-delay="0"
                data-aos-duration="1000"
                data-aos-anchor-placement="top-bottom"
                className={[classes.background, classes['background--grey'], classes['background--grey--down']].join(' ')}
            >
            </div>

            <div
                data-aos="slide-left"
                data-aos-offset="200"
                data-aos-delay="0"
                data-aos-duration="700"
                data-aos-anchor-placement="top-bottom"
                className={[classes.background, classes['background--white']].join(' ')}
            >
            </div>

        </section>
    );
}

BigSeparator.propTypes = {

};

export default BigSeparator;