import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';


const SEOquery = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        author
        keywords
        themeColor
      }
    }
  }
`;

function SEO(props) {
    const { site } = useStaticQuery(SEOquery);

    const {
        defaultTitle,
        defaultDescription,
        author,
        keywords,
        themeColor,
    } = site.siteMetadata;

    const seo = {
        title: props.title || defaultTitle,
        description: props.description || defaultDescription
    };

    return (
        <Helmet title={seo.title} htmlAttributes={{ lang : 'en' }}>

            <meta name="keywords" content={keywords.join(', ')} />
            <meta name="author" content={author} />
            <meta name="description" content={seo.description} />
            <meta name="theme-color" content={themeColor}></meta>

            {seo.title && (<meta property="og:title" content={seo.title} />)}

            {seo.description && (<meta property="og:description" content={seo.description} />)}

            {seo.title && (<meta name="twitter:title" content={seo.title} />)}

            {seo.description && (<meta name="twitter:description" content={seo.description} />)}
        </Helmet>
    );
}

SEO.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
}

export default SEO;